import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataShareService {
  sidebarStatus = new BehaviorSubject(true);
  currentsidebarStatus = this.sidebarStatus.asObservable();

  scrollStatus = new BehaviorSubject(false)
  currentscrollStatus = this.scrollStatus.asObservable();

  searchInput = new BehaviorSubject('');
  currentsearchInput = this.searchInput.asObservable();

  languageDisable = new BehaviorSubject(false);
  currentlanguageDisable = this.languageDisable.asObservable();

  profiledisabled = new BehaviorSubject(true);
  currentprofileDisabled = this.profiledisabled.asObservable();

  user = new BehaviorSubject(false);
  currentUser = this.user.asObservable();

  toggleMenu = new BehaviorSubject(true);
  currenttoggle = this.toggleMenu.asObservable();
  constructor() {}

  changeSidebarStatus(message: any) {
    this.sidebarStatus.next(message);
  }

  changeScrollStatus(message:any){
    this.scrollStatus.next(message)
  }
  search(message: any) {
    this.searchInput.next(message);
  }

  userChange(message: any) {
    this.user.next(message);
  }

  changelanguageStatus(status: boolean) {
    this.languageDisable.next(status);
  }

  changeprofileStatus(status: boolean) {
    this.profiledisabled.next(status);
  }

  changetoggleMenuStatus(status: boolean) {
    this.toggleMenu.next(status);
  }

  private messageSource = new BehaviorSubject<string>('Default Message');
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

}
