import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/services/language.service';
@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {

  private baseUrl = environment.apiUrl;
  private token: any = {};
  constructor( private http : HttpClient, private httpBackend: HttpBackend,private langService: LanguageService ) {}

  requestLogin(data:any){
   let httpClient = new HttpClient(this.httpBackend);
   return httpClient.post(this.baseUrl + 'login?ln='+ this.langService.getLang() , data)
  }

  getStorageToken() {
    return localStorage.getItem('token')
  }

  isLoggedIn(): boolean {
    this.token = this.getStorageToken()
    return (this.token) != null
  }

  logout() {
    localStorage.removeItem('token')
  }

  storeToken(token: any) {
    localStorage.setItem('token',  (token))
  }

  storeOttId(id: any) {
    localStorage.setItem('ottId',  (id))
  }

  storeChargeStatus(id: any) {
    localStorage.setItem('subscriptionStatus',  (id))
  }

  getOttId() {
    return localStorage.getItem('ottId')
  }


  getChargeStatus() {
    return localStorage.getItem('subscriptionStatus')
  }

  refreshToken() {
    return this.http.get(this.baseUrl+'refresh/token', {})
  }


}
